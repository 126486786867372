import React from 'react'

const GatsbyHead = () => {
	return (
		<>
			<meta name="application-name" content="Financefinity" />
			<link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon.png?v=2" />
			<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=2" />
			<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=2" />
			<link rel="manifest" href="/site.webmanifest?v=2" />
			<link rel="mask-icon" href="/safari-pinned-tab.svg?v=2" color="#dd1d93" />
			<link rel="shortcut icon" href="/favicon.ico?v=2" />
			<meta name="msapplication-TileColor" content="#3484a4" />
			<meta name="theme-color" content="#ffffff"></meta>
		</>
	)
}

export default GatsbyHead
