import React from 'react'

import { wpPageSeoProps } from '@contracts/page'

interface Props {
	data: wpPageSeoProps
}

const SEO = (props: Props) => {
	const { data } = props

	let opengraphImage: string | undefined = undefined
	let twitterImage: string | undefined = undefined

	if (data.opengraphImage && data.opengraphImage.localFile) {
		opengraphImage = data.opengraphImage.localFile.publicURL
	}

	if (data.twitterImage && data.twitterImage.localFile) {
		twitterImage = data.twitterImage.localFile.publicURL
	}

	return (
		<>
			<title>{data.title}</title>
			<meta name="description" content={data.metaDesc}></meta>
			<meta name="robots" content={data.metaRobotsNofollow + ", " + data.metaRobotsNoindex} />

			{/* Opengraph */}
			<meta name="og:title" content={data.opengraphTitle}></meta>
			<meta name="og:url" content={data.opengraphUrl}></meta>
			<meta name="og:site_name" content={data.opengraphSiteName}></meta>
			{data.opengraphDescription && data.opengraphDescription !== '' && (
				<meta name="og:description" content={data.opengraphDescription}></meta>
			)}
			{opengraphImage && <meta name="og:image" content={opengraphImage}></meta>}
			<meta name="og:type" content={data.opengraphType}></meta>
			<meta name={data.opengraphType + ':publisher'} content={data.opengraphPublisher}></meta>
			<meta name={data.opengraphType + ':modified_time'} content={data.opengraphModifiedTime}></meta>

			{/* Twitter */}
			<meta name="twitter:title" content={data.twitterTitle}></meta>
			{data.twitterDescription && data.twitterDescription !== '' && (
				<meta name="twitter:description" content={data.twitterDescription}></meta>
			)}
			<meta name="twitter:card" content="summary"></meta>
			{twitterImage && <meta name="twitter:image" content={twitterImage}></meta>}

			{data.schema && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: data.schema.raw }} />}

			{data.canonical && (
				<link rel="canonical" href={data.canonical} />
			)}
		</>
	)
}

export default SEO
