import React from 'react'

import Container from '@components/Layout/Container'

import * as styles from './Section.module.scss'

interface Props {
	children: React.ReactNode
	className?: string
	containerClassName?: string
	type?: 'wide'
	style?: React.CSSProperties
	backgroundImage?: React.ReactNode
	backgroundImageUrl?: string
	noPaddingBottom?: true
	noPadding?: true
}

const Section = React.forwardRef(
	(props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
		const sectionStyles = [styles.section]
		const containerStyles = [styles.container]

		if (props.className) {
			sectionStyles.push(props.className)
		}

		if (props.containerClassName) {
			containerStyles.push(props.containerClassName)
		}

		let backgroundImage

		if (props.backgroundImage) {
			sectionStyles.push(styles.hasBackgroundImage)

			backgroundImage = React.cloneElement(props.backgroundImage, {
				className: styles.backgroundImage,
			})
		}

		if (props.noPaddingBottom) {
			sectionStyles.push(styles.noPaddingBottom)
		}

		if (props.noPadding) {
			sectionStyles.push(styles.noPadding)
		}

		return (
			<section
				className={sectionStyles.join(' ')}
				style={props.style}
				ref={ref}
			>
				<Container
					class={containerStyles.join(' ')}
					wide={props.type == 'wide'}
				>
					{props.children}
				</Container>
				{backgroundImage && backgroundImage}
			</section>
		)
	}
)

Section.defaultProps = {
	style: {},
}

export default Section
