import React from 'react'

import Section from '@components/Content/Section/Section'
import GatsbyHead from '@components/Layout/GatsbyHead'

import Layout from '../components/Layout/Layout'
import SEO from '../components/Layout/SEO'
import Wysiwyg from '../components/Layout/Wysiwyg'
import { wpPageDefaultProps } from '../contracts/page'
import * as styles from './DefaultPage.module.scss'

const DefaultPage = (props: wpPageDefaultProps) => {
	const { data } = props.pageContext

	return (
		<Layout>
			<Section>
				<h1 className={styles.title}>{data.title}</h1>

				<article>
					<Wysiwyg content={data.content} pageContent={true} />
				</article>
			</Section>
		</Layout>
	)
}

export function Head(props: wpPageDefaultProps) {
	return (
		<>
			<GatsbyHead />
			<SEO data={props.pageContext.data.seo} />
		</>
	)
}

export default DefaultPage
